import { useGetSyncingAccountsQuery } from '@cointracker/graphql-types';
import { URLS } from '@cointracker/ui';
import { CostBasisContext } from 'components/CostBasisProvider';
import englishLocale from 'dayjs/locale/en';
import { useContext, useState } from 'react';
import dayjs from 'src/app/utils/dayjsConfig';
import {
  isAnyAssetSyncing,
  isExchangeSyncing,
  isWalletSyncing,
} from 'src/pages/Rebrand/Wallets/WalletsContainer/utils';
import { NavigationType } from 'src/pages/Rebrand/Wallets/constants';
import { getIconFromCurrency } from '../../../../../pages/Rebrand/TransactionForm/WalletSelect/hooks/useGetWallets';

export enum StatusReadyState {
  Ready,
  Syncing,
  Calculating,
  Failed,
  Loading,
  EmptyData,
}

export interface SyncAssetItem {
  title: string;
  subtitle: string;
  value: number;
  isSyncing: boolean;
  url: string;
  imgUrl: string;
}

export const useGetStatus = () => {
  const { isCostBasisRunning, isUndergoingAllocation, hasError, lastSyncedAt } =
    useContext(CostBasisContext);
  const [isStatusSheetOpen, setIsStatusSheetOpen] = useState(false);
  const lastUpdatedAt = dayjs(lastSyncedAt).locale(englishLocale)?.fromNow?.();

  const fiveMinutesAgo = dayjs().subtract(5, 'minutes');
  const hasMoreThan5MinutesPassedSinceLastSync =
    dayjs(lastSyncedAt).isBefore(fiveMinutesAgo);

  const shouldAllowUserToResync =
    isCostBasisRunning && hasMoreThan5MinutesPassedSinceLastSync;

  const { data, loading } = useGetSyncingAccountsQuery();
  const hasSyncingAssets = isAnyAssetSyncing(data, false);

  const syncingAssets: SyncAssetItem[] = [];

  data?.exchanges?.forEach((exchange) => {
    const syncAsset = {
      title: exchange.name,
      subtitle: `${exchange.assetsCount} assets`,
      value: exchange.value,
      isSyncing: isExchangeSyncing(exchange),
      url: `${URLS.WALLETS_PAGE}/${NavigationType.EXCHANGE}/${exchange.id}`,
      imgUrl: exchange.imageUrl,
    };
    if (syncAsset.isSyncing) {
      syncingAssets.push(syncAsset);
    }
  });

  data?.localWallets?.forEach((wallet) => {
    const syncAsset = {
      title: wallet.name,
      subtitle: wallet.address,
      value: wallet.value,
      isSyncing: isWalletSyncing(wallet),
      url: `${URLS.WALLETS_PAGE}/${NavigationType.WALLET}/${wallet.id}`,
      imgUrl: wallet.imageUrl ?? getIconFromCurrency(wallet?.currency),
    };
    if (syncAsset.isSyncing) {
      syncingAssets.push(syncAsset);
    }
  });

  let status = StatusReadyState.Ready;
  if (isCostBasisRunning || isUndergoingAllocation) {
    status = StatusReadyState.Calculating;
  }
  if (hasSyncingAssets) {
    status = StatusReadyState.Syncing;
  }
  if (hasError) {
    status = StatusReadyState.Failed;
  }
  if (loading) {
    status = StatusReadyState.Loading;
  }
  if (
    status === StatusReadyState.Ready &&
    data?.exchanges?.length === 0 &&
    data?.localWallets?.length === 0
  ) {
    status = StatusReadyState.EmptyData;
  }

  return {
    status,
    syncingAssets,
    shouldAllowUserToResync,
    lastUpdatedAt,
    isStatusSheetOpen,
    setIsStatusSheetOpen,
  };
};
