import { Banner, BannerProps, PageContainer } from '@cointracker/styleguide';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from 'src/app/user/context';
import { GATES } from 'src/common/statsig_flags';
import { useGate } from 'statsig-react';
import { AppInfoContext } from '../../../app/context/AppInfoContext';
import UniversalToPerWalletMigrationBanner from './UniversalToPerWalletMigrationBanner/UniversalToPerWalletMigrationBanner';

interface Messages {
  [key: string]: {
    closable: boolean;
  };
}

enum Category {
  Error = 'Error',
  Warning = 'Warning',
  Success = 'Success',
}

type BannerMessages = {
  [key in Category]?: Messages;
};
export const GlobalBanners = () => {
  const appInfo = useContext(AppInfoContext);
  const user = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [bannerMessages, setBannerMessages] = useState<BannerMessages>({});
  const [hasMigrationStarted, setMigrationStarted] = useState(false);
  const [hasMigrationFinished, setMigrationFinished] = useState(false);

  const { value: isIncrementalEnabled } = useGate(GATES.incremental_reads);

  const closeBanner = useCallback((category: Category, message: string) => {
    setBannerMessages((bannerMessages) => {
      delete bannerMessages[category][message];
      return { ...bannerMessages };
    });
  }, []);

  useEffect(() => {
    const handleMessage = (paramName: string, category: Category) => {
      const message = searchParams.get(paramName);
      if (message) {
        searchParams.delete(paramName);
        setSearchParams(searchParams);
        const decodedMessage = decodeURIComponent(message);
        setBannerMessages((oldBannerMessages) => ({
          ...oldBannerMessages,
          [category]: {
            ...oldBannerMessages[category],
            [decodedMessage]: { closable: true },
          },
        }));
      }
    };

    handleMessage('errorMessage', Category.Error);
    handleMessage('successMessage', Category.Success);
    handleMessage('warningMessage', Category.Warning);
  }, [searchParams, setSearchParams]);

  /**
   * Create a map of categories and their messages in a dictionary
   * There are some duplicate messages so remove those implicity by having a map of messages
   *
   * Ex.
   * {
   *    'warning': {
   *      'test warning': {closable: true },
   *      'hello warning': {closable: true }
   *     },
   *     'error': {
   *        'test error': {closable: true }
   *     }
   * }
   */
  useEffect(() => {
    // Add maintenance message if needed
    const warningMessages: Messages = {};
    const errorMessages: Messages = {};
    if (appInfo?.maintenanceMode) {
      warningMessages[appInfo?.maintenanceMessage ?? ''] = { closable: false };
    }

    // Add View As banner
    const isViewAsUser = appInfo?.isViewAsUser && user?.isAuthenticated;
    if (isViewAsUser) {
      const viewAsMessage = `Viewing as ${user?.displayUser?.email} ID: ${user?.viewAsUserId}`;
      warningMessages[viewAsMessage] = { closable: false };

      if (!user?.debugPermission) {
        const notAllowedToDebugMessage = `No permission to debug this account!`;
        errorMessages[notAllowedToDebugMessage] = { closable: false };
      }

      if (isIncrementalEnabled) {
        warningMessages['Incremental reads enabled'] = { closable: false };
      }
    }

    setBannerMessages((oldBannerMessages) => {
      const bannerMessages: BannerMessages = { ...oldBannerMessages };
      bannerMessages[Category.Error] = {
        ...bannerMessages[Category.Error],
        ...errorMessages,
      };
      bannerMessages[Category.Warning] = {
        ...bannerMessages[Category.Warning],
        ...warningMessages,
      };
      return bannerMessages;
    });
  }, [isIncrementalEnabled, appInfo, user]);

  if (!appInfo) return <></>;

  const shouldShowMigrationBanner =
    user?.isAuthenticated &&
    user?.country?.code === 'US' &&
    !hasMigrationFinished &&
    (hasMigrationStarted ||
      (!user?.costBasisByWallet && !user?.hasTransitioned));

  const hasAtLeastOneMessage = Object.entries(bannerMessages).some(
    ([, messages]) => Object.keys(messages).length > 0,
  );
  // Convert map of messages to list of banners to display
  return (
    <>
      {shouldShowMigrationBanner && (
        <PageContainer className="mt-48 !min-h-0">
          <UniversalToPerWalletMigrationBanner
            className="mb-10"
            onMigrationStarted={() => setMigrationStarted(true)}
            onMigrationFinished={() => setMigrationFinished(true)}
            disabled={
              !user?.lotAllocationMethod ||
              user?.isUndergoingAllocation ||
              hasMigrationStarted
            }
            disabledTooltipContent={
              !user?.lotAllocationMethod ? (
                <span>
                  You must set your tax lot allocation method in your{' '}
                  <a href="/user/profile/tax">tax settings</a> to migrate.
                </span>
              ) : user?.isUndergoingAllocation || hasMigrationStarted ? (
                <span>
                  Allocation is currently in progress and may take up to several
                  minutes to complete.
                </span>
              ) : null
            }
          />
        </PageContainer>
      )}
      {hasAtLeastOneMessage && (
        <PageContainer className="!min-h-0 first:mt-48">
          {Object.entries(bannerMessages).map(([category, messages]) =>
            Object.entries(messages).map(([message, messageProps]) => (
              <Banner
                className="mb-10"
                key={`${category}-${message}`}
                closable={messageProps?.closable}
                type={category.toLowerCase() as BannerProps['type']}
                onBannerClose={() => closeBanner(category as Category, message)}
              >
                {message}
              </Banner>
            )),
          )}
        </PageContainer>
      )}
    </>
  );
};
