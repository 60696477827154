import {
  Footer,
  SidebarInset,
  TaxProTopBar,
  TopBar,
  cn,
} from '@cointracker/styleguide';
import { ScrollArea } from '@radix-ui/themes';
import { Footer as RebrandFooter } from 'components/Rebrand/Footer';
import {
  Sidebar as RebrandSidebar,
  TopBar as RebrandTopBar,
} from 'components/Rebrand/Sidebar';
import { ConnectedViewingAsWidget } from 'components/Rebrand/TaxPros/common/ViewingAsWidget/ConnectedViewingAsWidget';
import { TrustPilotPrompt as RebrandTrustPilotPrompt } from 'components/Rebrand/TrustPilotPrompt';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAnalytics } from 'src/app/analytics';
import { AppInfoContext } from 'src/app/context/AppInfoContext';
import { logout } from 'src/app/router';
import { UserContext } from 'src/app/user/context';
import { URLS, isURLActive } from 'src/common/urls';
import { GlobalBanners as RebrandGlobalBanners } from 'src/components/Rebrand/GlobalBanners';
import usePrevious from 'src/hooks/usePrevious';
import { CbeDemoTopBar } from 'src/pages/Rebrand/CostBasisEngineDemo/CbeDemoTopbar/CbeDemoTopbar';
import { CBO_PROMO_KEY } from 'src/pages/Rebrand/Promotion/CoinbaseOne/constants';
import { AddWalletFlowProvider as RebrandedAddWalletFlowProvider } from 'src/pages/Rebrand/Wallets/WalletsHeader/AddWalletButton/AddWalletModal/provider';
import { PageLoading } from '../../../helpers/loading';
import { useIsMobileWebview } from '../../../hooks/useIsMobileWebview';
import { useCbeDemo } from '../../../pages/Rebrand/CostBasisEngineDemo/cbeDemoContext';
import { METAMASK_ACCOUNTS_KEY } from './constants';

const isHomePage = () =>
  isURLActive([URLS.MAIN_PAGE, URLS.DASHBOARD_PAGE, URLS.HOME_PAGE]);

export function Root() {
  const location = useLocation();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  // TODO: after rebrand we can remove this
  const appInfo = useContext(AppInfoContext);
  const [shouldCheckForRedirect, setShouldCheckForRedirect] = useState(true);
  const hasCoinbaseOneCode = !isEmpty(localStorage.getItem(CBO_PROMO_KEY));
  const hasMetamaskPrefilledWallets = !isEmpty(
    localStorage.getItem(METAMASK_ACCOUNTS_KEY),
  );
  const { isBroker } = useCbeDemo();

  if (
    appInfo.isLoaded &&
    shouldCheckForRedirect &&
    hasCoinbaseOneCode &&
    isHomePage()
  ) {
    // first thing to do is check for existance of Coinbase One Promotion and redirect if so
    // this prevents seeing the dashboard at all if the user should be on the promo page
    navigate(URLS.COINBASE_ONE_PROMOTION, { replace: true });
  }

  if (
    appInfo.isLoaded &&
    shouldCheckForRedirect &&
    hasMetamaskPrefilledWallets &&
    isHomePage()
  ) {
    const redirectUrl =
      URLS.ONBOARDING.ADD_WALLET_METAMASK +
      `&public_keys=${localStorage.getItem(METAMASK_ACCOUNTS_KEY)}`;
    localStorage.removeItem(METAMASK_ACCOUNTS_KEY);
    navigate(redirectUrl, { replace: true });
  }

  // On every route change, fire a page view event
  useEffect(() => {
    analytics?.page(null, location.pathname, {});
  }, [location, analytics]);

  /**
   * Only check if a redirect is needed once in the application lifecycle
   * Waits for appInfo to be loaded before checking for a redirect
   * If the user is on the home page and the appInfo returned a redirect for the home page,
   * then redirect the user to the new home page
   * Use cases:
   *  1. Redirect to onboarding page if user hasn't onboarded yet
   *  2. Redirect tax pros to tax pro dashboard
   */
  useEffect(() => {
    if (!appInfo.isLoaded) return;

    if (shouldCheckForRedirect) {
      setShouldCheckForRedirect(false); // only check once
      // if they have a coinbase one promotion we should have already redirected by now, so don't redirect again.
      if (
        isHomePage() &&
        !isEmpty(appInfo.webAppHomePage) &&
        !hasCoinbaseOneCode
      ) {
        navigate(appInfo.webAppHomePage, { replace: true });
      }
    }
  }, [
    appInfo.isLoaded,
    appInfo.webAppHomePage,
    navigate,
    shouldCheckForRedirect,
    hasCoinbaseOneCode,
  ]);

  const previousLocation: Location = usePrevious(location);
  useEffect(() => {
    // in some nested route cases where we generate more than 1 route per page, we don't want the page to scroll to top
    // when navigating inside the nested route
    if (location.state?.keepScrollPosition) {
      return;
    }
    // When selecting a filter in the transactions page, the page jumps to 0,0 because the location object changed
    // This check will prevent the jumping of tags https://linear.app/cointracker/issue/PPP-354/page-jumps-down-when-a-tag-is-selected
    // We haven't included this check for every page to be in the safe side, but we might introduce this for every page soon.
    // Ping PPP if you want to introduce your path here.
    if (
      previousLocation &&
      location.pathname.endsWith('/transactions') &&
      previousLocation.pathname.endsWith('/transactions')
    ) {
      return;
    }
    window.scrollTo(0, 0);
  }, [location, previousLocation]);

  const isOnTaxProsPage = window?.location?.pathname?.startsWith?.('/tax-pros');
  const isTaxPro =
    user?.isAuthenticated && user?.displayUser?.registeredAsTaxPro;

  const isTaxProMode = isTaxPro && isOnTaxProsPage;

  const isMobileWebview = useIsMobileWebview();

  if (!user.hasFetched) {
    return <PageLoading />;
  }

  const shouldResetScrollAreaDisplay = location.pathname.includes('/plans');
  // there's a very annoying bug because the radix scroll area is of type table
  // https://github.com/radix-ui/primitives/issues/2722 and
  // https://github.com/radix-ui/primitives/issues/926
  // In the plans page case it doesn't respect the max-width: 100% rule and it overflows the container in specific sizes
  const scrollAreaClasses = cn(
    'h-full w-full bg-background-default',
    shouldResetScrollAreaDisplay &&
      '[&>[data-radix-scroll-area-viewport]>div]:!block',
  );

  if (isTaxProMode) {
    return (
      <RebrandedAddWalletFlowProvider>
        <TaxProTopBar
          pathname={location.pathname}
          userEmail={user?.email}
          onLogout={() => logout()}
        />
        <div className="max-auto h-full w-full pt-[78px] md:pt-[68px]">
          <RebrandTrustPilotPrompt />
          <RebrandGlobalBanners />
          <Outlet />
          <ConnectedViewingAsWidget />
          <RebrandFooter />
        </div>
      </RebrandedAddWalletFlowProvider>
    );
  }

  if (user.isAuthenticated) {
    return (
      <RebrandedAddWalletFlowProvider>
        <CbeDemoTopBar shouldBeFixed />
        <RebrandTopBar shouldBeFixed />
        <RebrandSidebar />
        {/* we add the padding here for the topbar on mobile views - justify forces footer to bottom on short views */}
        <SidebarInset
          className={cn(
            'max-auto h-full max-w-full justify-between bg-background-default md:max-w-[calc(100dvw-var(--sidebar-width))] md:pt-0 peer-data-[state=collapsed]:md:max-w-[calc(100dvw-var(--sidebar-width-icon))]',
            {
              'pt-80': !isMobileWebview,
              'md:max-w-full md:pt-80 peer-data-[state=collapsed]:md:max-w-full':
                user.enableCbe && !isBroker,
            },
          )}
        >
          <RebrandTrustPilotPrompt />
          <RebrandGlobalBanners />
          <Outlet />
          <ConnectedViewingAsWidget />
          <RebrandFooter />
        </SidebarInset>
      </RebrandedAddWalletFlowProvider>
    );
  }

  // for public pages like /plans
  return (
    <RebrandedAddWalletFlowProvider>
      <ScrollArea className={scrollAreaClasses} scrollbars="both">
        <TopBar pathname={location.pathname} analytics={analytics} />
        <RebrandTrustPilotPrompt />
        <RebrandGlobalBanners />
        <Outlet />
        <ConnectedViewingAsWidget />
        <Footer />
      </ScrollArea>
    </RebrandedAddWalletFlowProvider>
  );
}

export default Root;
