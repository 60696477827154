import { Avatar } from '@cointracker/styleguide/src/LoggedIn/Avatar';
import {
  ArrowLeft,
  ArrowsLeftRight,
  BoxArrowUp,
  Calendar,
  ChartBar,
  ChartLine,
  ChartPie,
  Cube,
  Eye,
  File,
  FileCsv,
  FileX,
  Gear,
  HandDeposit,
  Heartbeat,
  Money,
  PiggyBank,
  QuestionMark,
  Recycle,
  Robot,
  Sparkle,
  User as UserIcon,
  UserList,
  UserPlus,
  Wallet,
} from '@phosphor-icons/react';
import { matchPath } from 'react-router-dom';
import { type AuthenticatedUser, type User } from 'src/app/user/context';
import dayjs from 'src/app/utils/dayjsConfig';
import { URLS } from 'src/common/urls';
import { VIEW_MODES } from 'src/pages/Rebrand/CostBasisEngineDemo/utils';

export const getTaxPageUrl = (taxYearToUse: number) => {
  return `${URLS.TAX_PAGE}/${taxYearToUse || dayjs().format('YYYY')}`;
};

export function isAuthenticatedUser(user: User): user is AuthenticatedUser {
  return user.hasFetched && user.isAuthenticated;
}

// CBE "Broker" sidebar items
const BROKER_ITEMS = (taxPageUrl: string) => [
  [{ slug: URLS.EDIT_USER_PROFILE_TAX }],
  [{ slug: taxPageUrl }],
  [{ slug: URLS.TRANSACTIONS_PAGE }],
  [{ slug: URLS.TAX_LOTS }],
  [{ slug: URLS.DISPOSED_LOTS }],
  [{ slug: 'break' }, { title: 'first-break-line' }],
  [{ slug: 'demo', subItems: DEMO_CBE_ITEMS() }, { title: 'Demo Only' }],
];
// normal consumer-web sidebar items
const CONSUMER_ITEMS = (taxPageUrl: string) => [
  [{ slug: URLS.HOME_PAGE }],
  [{ slug: URLS.RECONCILIATION }],
  [{ slug: URLS.WALLETS_PAGE }],
  [{ slug: URLS.TRANSACTIONS_PAGE }],
  [{ slug: URLS.SPAM }],
  [{ slug: 'break' }, { title: 'first-break-line' }],
  [{ slug: taxPageUrl }],
  [{ slug: URLS.TAX_LOSS_HARVEST }],
  [{ slug: 'break' }, { title: 'second-break-line' }],
  [{ slug: URLS.PERFORMANCE_PAGE }],
  [{ slug: URLS.PRICES_PAGE }],
];

const DEMO_CBE_ITEMS = () => [
  [{ slug: URLS.UPLOAD_CSV }],
  [{ slug: URLS.ADD_SELL_ORDER }],
  [{ slug: URLS.ADD_DEPOSIT }],
  [{ slug: URLS.COST_BASIS_METHOD_CALENDAR }],
];

const BROKER_CONSUMER_CBE_ITEMS = (taxPageUrl: string) => [
  [{ slug: taxPageUrl }],
  [{ slug: URLS.TRANSACTIONS_PAGE }],
  [{ slug: URLS.TAX_LOSS_HARVEST }],
  [{ slug: URLS.WALLETS_PAGE }],
];

export interface KnownNavigationItem {
  slug: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: (props?: any) => JSX.Element;
  isActive?: boolean;
  subItems?: KnownNavigationItem[];
}

const getKnownNavigationItem = (
  {
    slug,
    taxPageUrl,
    currentPathName,
  }: { slug: string; taxPageUrl: string; currentPathName: string },
  overrides?: Partial<KnownNavigationItem>,
) => {
  switch (slug) {
    case 'break':
    case 'demo':
      return {
        slug,
        isActive: false,
        icon: null,
        ...overrides,
      };
    case URLS.HOME_PAGE:
      return {
        slug,
        title: 'Portfolio',
        icon: (props) => (
          <ChartPie
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.HOME_PAGE, currentPathName),
        ...overrides,
      };
    case URLS.RECONCILIATION:
      return {
        slug,
        title: 'Account health',
        icon: (props) => (
          <Heartbeat
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.RECONCILIATION, currentPathName),
        ...overrides,
      };
    case URLS.SPAM:
      return {
        slug,
        title: 'Spam',
        icon: (props) => (
          <FileX size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
        ),
        isActive: getIsActiveStateForPath(URLS.SPAM, currentPathName),
        ...overrides,
      };
    case URLS.TAX_LOSS_HARVEST:
      return {
        slug,
        title: 'Tax loss harvesting',
        icon: (props) => (
          <Money size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
        ),
        isActive: getIsActiveStateForPath(
          URLS.TAX_LOSS_HARVEST,
          currentPathName,
        ),
        ...overrides,
      };
    case URLS.PERFORMANCE_PAGE:
      return {
        slug,
        title: 'Performance',
        icon: (props) => (
          <ChartBar
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(
          URLS.PERFORMANCE_PAGE,
          currentPathName,
        ),
        ...overrides,
      };
    case URLS.PRICES_PAGE:
      return {
        slug,
        title: 'Prices',
        icon: (props) => (
          <ChartLine
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.PRICES_PAGE, currentPathName),
        ...overrides,
      };
    case URLS.WALLETS_PAGE:
      return {
        slug,
        title: 'Wallets',
        icon: (props) => (
          <Wallet
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.WALLETS_PAGE, currentPathName),
        ...overrides,
      };
    case URLS.EDIT_USER_PROFILE_TAX:
      return {
        slug,
        title: 'Account',
        icon: (props) => (
          <Gear size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
        ),
        isActive: getIsActiveStateForPath(
          URLS.EDIT_USER_PROFILE_TAX,
          currentPathName,
        ),
        ...overrides,
      };
    case taxPageUrl:
      return {
        slug,
        title: 'Taxes',
        icon: (props) => (
          <File size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
        ),
        isActive: getIsActiveStateForPath(URLS.TAX_PAGE, currentPathName),
        ...overrides,
      };
    case URLS.TRANSACTIONS_PAGE:
      return {
        slug,
        title: 'Transactions',
        icon: (props) => (
          <ArrowsLeftRight
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(
          URLS.TRANSACTIONS_PAGE,
          currentPathName,
        ),
        ...overrides,
      };
    case URLS.ADD_DEPOSIT:
      return {
        slug,
        title: 'Add deposit',
        icon: (props) => (
          <PiggyBank
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.ADD_DEPOSIT, currentPathName),
        ...overrides,
      };
    case URLS.TAX_LOTS:
      return {
        slug,
        title: 'Tax lots',
        icon: (props) => (
          <Cube size={18} className="h-18 min-h-18 w-18 min-w-18" {...props} />
        ),
        isActive: getIsActiveStateForPath(URLS.TAX_LOTS, currentPathName),
        ...overrides,
      };
    case URLS.DISPOSED_LOTS:
      return {
        slug,
        title: 'Disposed lots',
        icon: (props) => (
          <BoxArrowUp
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.DISPOSED_LOTS, currentPathName),
        ...overrides,
      };
    case URLS.UPLOAD_CSV:
      return {
        slug,
        title: 'Upload CSV',
        icon: (props) => (
          <FileCsv
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.UPLOAD_CSV, currentPathName),
        ...overrides,
      };
    case URLS.ADD_SELL_ORDER:
      return {
        slug,
        title: 'Add sell order',
        icon: (props) => (
          <HandDeposit
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(URLS.ADD_SELL_ORDER, currentPathName),
        ...overrides,
      };
    case URLS.COST_BASIS_METHOD_CALENDAR:
      return {
        slug,
        title: 'Cost basis method',
        icon: (props) => (
          <Calendar
            size={18}
            className="h-18 min-h-18 w-18 min-w-18"
            {...props}
          />
        ),
        isActive: getIsActiveStateForPath(
          URLS.COST_BASIS_METHOD_CALENDAR,
          currentPathName,
        ),
      };
    default: {
      // return custom value / reflect what was passed.
      return {
        slug,
        ...overrides,
      };
    }
  }
};

function mapToItems(item, taxPageUrl, currentPathName) {
  let subItems = [];
  if (item[0].slug === 'demo') {
    subItems = (item[1].subItems ?? DEMO_CBE_ITEMS()).map((subItem) =>
      getKnownNavigationItem(
        { slug: subItem[0].slug, taxPageUrl, currentPathName },
        subItem[1],
      ),
    );
  }
  return {
    ...getKnownNavigationItem(
      { slug: item[0].slug, taxPageUrl, currentPathName },
      item[1],
    ),
    subItems,
  };
}
interface getNavigationItemsProps {
  taxPageUrl: string;
  currentPathName: string;
  isMobileNavigation?: boolean;
  cbeEnabled: boolean;
  isBroker: boolean;
}
export const getNavigationItems = ({
  taxPageUrl,
  currentPathName,
  isBroker,
  cbeEnabled,
}: getNavigationItemsProps) =>
  (cbeEnabled
    ? isBroker
      ? BROKER_ITEMS(taxPageUrl)
      : BROKER_CONSUMER_CBE_ITEMS(taxPageUrl)
    : CONSUMER_ITEMS(taxPageUrl)
  ).map((item) => mapToItems(item, taxPageUrl, currentPathName));

interface getAccountNavItemProps {
  userEmail: string;
  userIsAdmin: boolean;
  onNavigateToSupport: () => void;
  onClickChatWithSupport: () => void;
  onClickViewAs: () => void;
  onLogout: () => void;
  onResetDemoUser: () => void;
  currentPathName: string;
  cbeEnabled: boolean;
  onClickToggleDemoViewMode: (
    mode: (typeof VIEW_MODES)[keyof typeof VIEW_MODES],
  ) => void;
  isBroker: boolean;
}
export const getAccountNavItem = ({
  userEmail,
  userIsAdmin,
  onNavigateToSupport,
  onClickChatWithSupport,
  onClickViewAs,
  onLogout,
  onResetDemoUser,
  onClickToggleDemoViewMode,
  currentPathName,
  cbeEnabled = false,
  isBroker = false,
}: getAccountNavItemProps) => {
  if (cbeEnabled) {
    return {
      slug: null,
      title: isBroker ? 'Broker Portal' : 'User Portal',
      isActive:
        getIsActiveStateForPath(URLS.EDIT_USER_PROFILE, currentPathName) ||
        getIsActiveStateForPath(
          URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE,
          currentPathName,
        ) ||
        getIsActiveStateForPath(URLS.VIEW_TAX_PRO_CLIENTS, currentPathName),
      isUserIcon: true,
      icon: (props) => <Avatar variant="color" size="xs" {...props} />,
      subItems: [
        {
          slug: URLS.TRANSACTIONS_PAGE,
          title: `Switch to Broker Portal`,
          icon: () => (
            <UserList size={18} className="h-18 min-h-18 w-18 min-w-18" />
          ),
          isActive: isBroker,
          action: () => onClickToggleDemoViewMode(VIEW_MODES.BROKER),
        },
        {
          slug: URLS.TRANSACTIONS_PAGE,
          title: `Switch to User Portal`,
          icon: () => (
            <UserIcon size={18} className="h-18 min-h-18 w-18 min-w-18" />
          ),
          isActive: !isBroker,
          action: () => onClickToggleDemoViewMode(VIEW_MODES.CONSUMER),
        },
        {
          slug: URLS.EDIT_USER_PROFILE,
          title: 'Settings',
          isActive: getIsActiveStateForPath(
            URLS.EDIT_USER_PROFILE,
            currentPathName,
          ),
          icon: () => (
            <Gear size={18} className="h-18 min-h-18 w-18 min-w-18" />
          ),
        },
        {
          slug: null,
          title: 'Sign out',
          subTitle: userEmail,
          isActive: false,
          icon: () => (
            <ArrowLeft size={18} className="h-18 min-h-18 w-18 min-w-18" />
          ),
          action: onLogout,
        },
        {
          slug: 'demo-break-1',
          title: null,
          break: true,
          icon: () => null,
        },
        {
          slug: null,
          title: 'Reset demo account',
          isDangerous: true,
          icon: () => (
            <Recycle
              size={18}
              className="h-18 min-h-18 w-18 min-w-18 text-text-negative"
            />
          ),
          action: onResetDemoUser,
        },
      ],
    };
  }
  return {
    slug: null,
    title: 'Account',
    isActive:
      getIsActiveStateForPath(URLS.EDIT_USER_PROFILE, currentPathName) ||
      getIsActiveStateForPath(
        URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE,
        currentPathName,
      ) ||
      getIsActiveStateForPath(URLS.VIEW_TAX_PRO_CLIENTS, currentPathName),
    isUserIcon: true,
    icon: (props) => <Avatar variant="color" size="xs" {...props} />,
    subItems: [
      {
        slug: URLS.EDIT_USER_PROFILE,
        title: 'Settings',
        isActive: getIsActiveStateForPath(
          URLS.EDIT_USER_PROFILE,
          currentPathName,
        ),
        icon: () => <Gear size={18} className="h-18 min-h-18 w-18 min-w-18" />,
      },
      {
        slug: URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE,
        title: 'Subscription plans',
        isActive: getIsActiveStateForPath(
          URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE,
          currentPathName,
        ),
        icon: () => (
          <Sparkle size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
      },
      {
        slug: URLS.VIEW_TAX_PRO_CLIENTS,
        title: 'Add a tax professional',
        isActive: getIsActiveStateForPath(
          URLS.VIEW_TAX_PRO_CLIENTS,
          currentPathName,
        ),
        icon: () => (
          <UserPlus size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
      },
      {
        slug: null,
        title: 'Help center',
        isActive: false,
        icon: () => (
          <QuestionMark size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
        action: onNavigateToSupport,
      },
      {
        slug: null,
        title: 'Chat with support',
        isActive: false,
        icon: () => <Robot size={18} className="h-18 min-h-18 w-18 min-w-18" />,
        action: onClickChatWithSupport,
      },
      {
        slug: null,
        title: 'View as',
        isActive: false,
        icon: () => <Eye size={18} className="h-18 min-h-18 w-18 min-w-18" />,
        hide: !userIsAdmin,
        action: onClickViewAs,
      },
      {
        slug: null,
        title: 'Sign out',
        subTitle: userEmail,
        isActive: false,
        icon: () => (
          <ArrowLeft size={18} className="h-18 min-h-18 w-18 min-w-18" />
        ),
        action: onLogout,
      },
    ],
  };
};

export const getIsActiveStateForPath = (
  slug: string,
  currentPathName: string,
) => {
  switch (slug) {
    case URLS.HOME_PAGE:
      // mobile navigation components deviate from the desktop ones for the time being and we need to make sure different
      // items are active at different components
      return Boolean(matchPath(URLS.HOME_PAGE, currentPathName));
    case URLS.WALLETS_PAGE:
      return Boolean(matchPath(`${URLS.WALLETS_PAGE}/*`, currentPathName));
    case URLS.TRANSACTIONS_PAGE:
      return Boolean(matchPath(`${URLS.TRANSACTIONS_PAGE}/*`, currentPathName));
    case URLS.RECONCILIATION:
      return Boolean(matchPath(`${URLS.RECONCILIATION}/*`, currentPathName));
    case URLS.SPAM:
      return Boolean(matchPath(`${URLS.SPAM}/*`, currentPathName));
    case URLS.ADD_SELL_ORDER:
      return Boolean(matchPath(`${URLS.ADD_SELL_ORDER}`, currentPathName));
    case URLS.TAX_PAGE:
      return Boolean(matchPath(`${URLS.TAX_PAGE}/*`, currentPathName));
    case URLS.PRICES_PAGE:
      return Boolean(matchPath(`${URLS.PRICES_PAGE}/*`, currentPathName));
    case URLS.EDIT_USER_PROFILE_TAX:
      return (
        Boolean(matchPath(URLS.EDIT_USER_PROFILE_TAX, currentPathName)) ||
        Boolean(matchPath(`${URLS.EDIT_USER_PROFILE}/account`, currentPathName))
      );
    case URLS.UNIFIED_SUBSCRIPTIONS_PLANS_CHECKOUT_PAGE:
      return Boolean(matchPath(URLS.EDIT_USER_PROFILE, currentPathName));
    case URLS.VIEW_TAX_PRO_CLIENTS:
      return Boolean(matchPath(URLS.VIEW_TAX_PRO_CLIENTS, currentPathName));
    case URLS.TAX_LOTS:
      return Boolean(matchPath(`${URLS.TAX_LOTS}/*`, currentPathName));
    default:
      // Changed this to check matchPath on the slug instead of just passing false
      return Boolean(matchPath(slug ?? '', currentPathName));
  }
};
