import {
  InlineBanner,
  InlineBannerContent,
  TextInput,
} from '@cointracker/styleguide';
import { Body3, Button } from '@cointracker/styleguide/src/LoggedIn';
import React, { useCallback, useState } from 'react';
import {
  GetValidationErrorsForCsvQuery,
  GetValidationErrorsForCsvQueryVariables,
  Integration,
} from 'src/types/graphql-types';

import { LazyQueryHookOptions, LazyQueryResult } from '@apollo/client';
import { Info } from '@phosphor-icons/react';
import { DuplicateTransactionsCallout } from 'components/DuplicateTransactionsCallout';
import { isEmpty } from 'lodash-es';
import { useFileUploader } from 'src/common/file-uploader-hook';
import { CoverageInfoContainer } from '../../CoverageInfoContainer';
import { Instructions } from '../../Instructions';
import { IntegrationFormModalFooter } from '../../IntegrationFormModalFooter';
import { FileInput } from './FileInput';
import { GenericInstructions } from './GenericInstructions';
import { ValidationErrors, validate } from './validate';

export interface CSVFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  formAction?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  fileUrl?: string;
  isSubmitting?: boolean;
  onFileChange?: (filepath?: string) => void;
  importError?: string;
  getValidationErrorsForCsv?: (
    options?: LazyQueryHookOptions<
      GetValidationErrorsForCsvQuery,
      { csvFileUrl?: string }
    >,
  ) => Promise<
    LazyQueryResult<
      GetValidationErrorsForCsvQuery,
      GetValidationErrorsForCsvQueryVariables
    >
  >;
  isGeneric?: boolean;
  needsDuplicatesCallout?: boolean;
}

const getButtonText = (
  isSubmitting: boolean,
  isUploading: boolean,
  isValidating: boolean,
) => {
  let buttonText = 'Import';
  if (isSubmitting) {
    buttonText = 'Importing';
  }
  if (isUploading) {
    buttonText = 'Uploading';
  }
  if (isValidating) {
    buttonText = 'Validating';
  }
  return buttonText;
};

export const CSVForm = (props: CSVFormProps) => {
  const {
    integration,
    formAction,
    fileUrl,
    isSubmitting,
    onFileChange,
    onSubmit,
    importError,
    getValidationErrorsForCsv,
    isGeneric,
    needsDuplicatesCallout,
  } = props;
  const { upload, loading: isUploading } = useFileUploader();
  const [isValidating, setIsValidating] = useState(false);
  const showNewInstructionsComponent = integration.slug === 'coinbase';
  const csvIntegrationCoverageInfo =
    integration?.info?.csvIntegrationCoverageInfo;

  const [errors, setErrors] = useState<ValidationErrors>({});

  const onSubmitCallback = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const walletName = formData.get('walletName') as string;

      const errors = validate({ fileUrl, walletName });
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      onSubmit(event);
    },
    [onSubmit, fileUrl],
  );

  const onFileChangeCallback = useCallback(
    (filePath?: string) => {
      if (filePath) {
        setErrors({ fileMissing: false });
      }
      onFileChange?.(filePath);
    },
    [onFileChange],
  );

  return (
    <form
      encType="multipart/form-data"
      action={formAction}
      onSubmit={onSubmitCallback}
    >
      <div className="mt-24 flex flex-col gap-24">
        {!isGeneric && csvIntegrationCoverageInfo && (
          <CoverageInfoContainer html={csvIntegrationCoverageInfo} />
        )}

        {importError &&
          importError.split('\n').map((error, i) => (
            <InlineBanner variant="negative" key={i}>
              <Info size={25} />
              <InlineBannerContent>{error}</InlineBannerContent>
            </InlineBanner>
          ))}
        {needsDuplicatesCallout && (
          <DuplicateTransactionsCallout integrationName={integration.name} />
        )}
        <input
          type="hidden"
          id="file_url"
          name="file_url"
          value={fileUrl}
          style={{ display: 'none' }}
        />
        <TextInput
          id="walletName"
          name="walletName"
          defaultValue={integration.name}
          disabled={isSubmitting || isUploading || isValidating}
          label="Wallet name"
          placeholder="Type wallet name"
          autoFocus={true}
          hasError={errors.walletNameMissing}
          errorMessage="Wallet name is required"
        />
        <div className="flex flex-col gap-4">
          <Body3>CSV file</Body3>
          <FileInput
            isImporting={isSubmitting}
            onChange={onFileChangeCallback}
            fileFormats={integration?.info?.supportedFileFormats}
            setIsImportDisabled={() => {}}
            upload={upload}
            isUploading={isUploading}
            isValidating={isValidating}
            setIsValidating={setIsValidating}
            getValidationErrorsForCsv={getValidationErrorsForCsv}
            isGeneric={isGeneric}
            integrationSlug={integration.slug}
            hasError={errors.fileMissing}
          />
          {errors.fileMissing && (
            <Body3 className="text-text-negative">File is required</Body3>
          )}
        </div>

        {isGeneric ? (
          <GenericInstructions integration={integration} />
        ) : (
          <>
            {showNewInstructionsComponent ? (
              <GenericInstructions integration={integration} />
            ) : (
              <Instructions integration={integration} showCSVInstructions />
            )}
          </>
        )}
      </div>
      <IntegrationFormModalFooter>
        <Button
          variant="primary"
          loading={isSubmitting || isUploading || isValidating}
          type="submit"
          size="large"
        >
          {getButtonText(isSubmitting, isUploading, isValidating)}
        </Button>
      </IntegrationFormModalFooter>
    </form>
  );
};
