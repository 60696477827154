import { createContext, ReactNode, useEffect, useMemo } from 'react';
import usePrevious from 'src/hooks/usePrevious';
import { useWindowFocus } from 'src/hooks/useWindowFocus';
import {
  CostBasisState,
  useGetCostBasisStatusQuery,
} from 'src/types/graphql-types';
import { useVariableSpeedPolling } from './hooks/useVariableSpeedPolling';

export const CostBasisContext = createContext({
  isCostBasisRunning: false,
  isUndergoingAllocation: false,
  lastSyncedAt: null,
  hasError: false,
  startFasterPollingForSyncCompletion: () => {},
  costBasisRecomputationDate: null,
});

export function CostBasisProvider({ children }: { children: ReactNode }) {
  const { data, startPolling, stopPolling, refetch } =
    useGetCostBasisStatusQuery();

  const { isWindowFocused } = useWindowFocus();
  const { triggerFasterPolling, stopFasterPolling } = useVariableSpeedPolling({
    pollingFn: () => {
      if (!isWindowFocused) {
        return;
      }
      refetch();
    },
  });

  const previousWindowFocused = usePrevious(isWindowFocused);
  useEffect(() => {
    if (!previousWindowFocused && isWindowFocused) {
      triggerFasterPolling();
      return;
    }

    if (!isWindowFocused) {
      stopFasterPolling();
    }
  }, [
    previousWindowFocused,
    isWindowFocused,
    triggerFasterPolling,
    stopFasterPolling,
  ]);

  const isCostBasisRunning = useMemo(() => {
    switch (data?.userInfo?.costBasisStatus?.state) {
      case CostBasisState.Enqueued:
      case CostBasisState.Scheduled:
      case CostBasisState.Started:
        // Currently we will show all cost basis jobs as running even non-high priority ones
        // if (data.userInfo.costBasisStatus.priority === QueuePriority.High) {
        //   return true
        // }
        return true;
      default:
        return false;
    }
  }, [data]);

  const prevIsCostBasisRunning = usePrevious(isCostBasisRunning);

  const costBasisRecomputationDate = data?.userInfo?.costBasisRecomputationDate;

  useEffect(() => {
    const shouldStartPolling = !prevIsCostBasisRunning && isCostBasisRunning;
    stopPolling(); // this is in case the gate value flips, to clean up any polling that might be happening
    if (shouldStartPolling) {
      triggerFasterPolling();
    }
    if (!isCostBasisRunning) {
      stopFasterPolling();
    }
  }, [
    data,
    isCostBasisRunning,
    prevIsCostBasisRunning,
    startPolling,
    stopFasterPolling,
    stopPolling,
    triggerFasterPolling,
  ]);

  return (
    <CostBasisContext.Provider
      value={{
        isCostBasisRunning: isCostBasisRunning,
        isUndergoingAllocation: data?.userInfo?.isUndergoingAllocation,
        lastSyncedAt: data?.userInfo?.costBasisLastSyncedAt,
        hasError:
          data?.userInfo?.costBasisStatus?.state === CostBasisState.Failed &&
          !isCostBasisRunning,
        startFasterPollingForSyncCompletion: triggerFasterPolling,
        costBasisRecomputationDate: costBasisRecomputationDate,
      }}
    >
      {children}
    </CostBasisContext.Provider>
  );
}
